class UsersApi {

    isUserExists(email: string): Promise<{ isUserExists: boolean }> {

        if (!email) {
            return Promise.resolve({ isUserExists: false });
        }

        return fetch(`${process.env.REACT_APP_ABLE_API}/users/isUserActive?email=${encodeURIComponent(email)}`)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                return response
                    .json()
                    .then(json => Promise.reject(json));
            }).then((response) => {
                return response.data;
            }).then(({ isUserActive }) => {
                // @TODO: Refactor it, (for now hardcode) because function changes
                return { isUserExists: isUserActive };
            }).catch((e) => {
                console.log('[isUserExists request]', e);
                return Promise.resolve({ isUserExists: false });
            });
    }
}

export const UsersApiProvider = new UsersApi();
