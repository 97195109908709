import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    popoverWrapper: {
        position: 'absolute',
        display: 'flex',
        columnGap: '10px',
        opacity: 0,
        animation: 'opacityAnimation 0.7s ease-out 0.7s forwards'
    },
    dotSymbol: {
        borderRadius: '50%',
        width: '13px',
        minWidth: '13px',
        height: '13px',
        backgroundColor: '#36B992',
        border: '3px solid #FFFFFF'
    },
    text: {
        color: '#36B992',
        fontFamily: 'SF Pro Text',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '14px',
        textTransform: 'uppercase',
        textAlign: 'center'
    },
    l_p_3_0: {
        top: '6%',
        left: '10%',
    },
    l_p_3_1: {
        top: '69%',
        left: '26%',
        width: '29%',
        flexDirection: 'row-reverse',
    },
    l_p_6_1: {
        top: '35%',
        left: '29%',
    },
    l_p_6_2: {
        top: '58%',
        left: '44%',
    },
    l_p_6_3: {
        top: '76%',
        left: '35%',
        width: '28%',
        flexDirection: 'row-reverse',
    },
    l_p_6_4: {
        top: '87%',
        left: '57%',
        width: '27%',
        flexDirection: 'row-reverse',

        '& span[class^="text"]': {
            marginTop: '6px'
        },
    },
    '@media (max-width: 540px)' : {
        l_p_3_1: {
            top: '68.5%',
        },
        l_p_4_2: {
            top: '81.5%'
        },
        l_p_6_1: {
            left: '30%'
        },
        l_p_6_3: {
            top: '77%',
            width: '29%',
        },
        l_p_6_4: {
            top: '86%',
            left: '55%'
        },
        l_p_12_5: {
            left: '25%',
            width: '31%',
        },
    },
    '@media (max-width: 500px)' : {},
    '@media (max-width: 480px)' : {},
    '@media (max-width: 460px)' : {},
    '@media (max-width: 440px)' : {
        l_p_3_0: {
            top: '5%'
        },
        l_p_3_1: {
            top: '65.5%',
        },
    },
    '@media (max-width: 428px)' : {
        l_p_3_1: {
            left: '16%',
            width: '39%',
        },
        l_p_6_1: {
            left: '30.5%'
        },
        l_p_6_2: {
            left: '10%',
            flexDirection: 'row-reverse',
            width: '38%',

            '& span[class^="text"]': {
                fontSize: '13px'
            }
        },
        l_p_6_3: {
            left: '29%',
            width: '37%'
        },
        l_p_6_4: {
            top: '85%',
            left: '43%',
            width: '38%',

            '& span[class^="text"]': {
                marginTop: '2px'
            }
        },
    },
    '@media (max-width: 414px)' : {
        l_p_3_1: {
            left: '15%',
            width: '41%',
        },
        l_p_4_2: {
            top: '80%',
            width: '41%',
        },
        l_p_6_2: {
            left: '10%',
            width: '39%',
        },
        l_p_6_3: {
            left: '27%',
            width: '39%',
        },
        l_p_6_4: {
            left: '42%'
        },
        l_p_12_5: {
            top: '67%',
            left: '15%',
            width: '40%',
        },
    },
    '@media (max-width: 393px)' : {
        l_p_3_0: {
            top: '4.5%',
        },
        l_p_3_1: {
            left: '14%',
            width: '42%',
        },
        l_p_6_1: {
            left: '31%'
        },
        l_p_6_2: {
            left: '7%',
            width: '42%'
        },
        l_p_6_3: {
            top: '76%',
            left: '24%',
            width: '41%'
        },
        l_p_6_4: {
            top: '84.5%',
            left: '40%',
            width: '40%',
        },
    },
    '@media (max-width: 390px)' : {
        l_p_4_2: {
            left: '26%'
        },
        l_p_5_2: {
        },
        l_p_6_4: {
            top: '83.5%',

            '& span[class^="text"]': {
                fontSize: '13px'
            }
        },
        l_p_12_5: {
            left: '14%'
        }
    },
    '@media (max-width: 375px)' : {
        l_p_3_0: {
            top: '4.5%',
        },
        l_p_3_1: {
            left: '12%',
            width: '45%'
        },
        l_p_6_1: {
            left: '32%'
        },
        l_p_6_2: {
            left: '8%',
            width: '41%',

            '& span[class^="text"]': {
                fontSize: '12px'
            }
        },
        l_p_6_3: {
            top: '75%',
            left: '22%',
            width: '43%',
        },
        l_p_6_4: {
            left: '39%',
            width: '42%',

            '& span[class^="text"]': {
                fontSize: '10px',
                marginTop: '6px'
            }
        },
        l_p_12_3: {

            '& span[class^="text"]': {
                marginTop: '-15px',
                marginLeft: '-15px'
            }
        },
        l_p_12_5: {
            top: '66%',
            left: '10%',
            width: '45%',
        },
    },
    '@media (max-width: 360px)' : {
        l_p_3_0: {
            top: '3.5%',
        },
        l_p_3_1: {
            top: '67%',
            left: '9%',
            width: '48%',
        },
        l_p_6_2: {
            left: '10%',
            width: '40%',

            '& span[class^="text"]': {
                fontSize: '11px'
            }
        },
        l_p_6_3: {
            top: '74%',
            left: '19%',
            width: '45%'
        },
        l_p_6_4: {
            left: '36%',
            width: '45%',

            '& span[class^="text"]': {
                fontSize: '10px',
                marginTop: '5px'
            }
        },
    },
    '@media (max-width: 320px)' : {
        l_p_3_0: {
            top: '3.5%',

            '& span[class^="text"]': {
                marginTop: '-6px'
            },
        },
        l_p_3_1: {
            left: '10%',

            '& span[class^="text"]': {
                fontSize: '12px'
            },
        },
        l_p_6_1: {
            left: '33%',

            '& span[class^="text"]': {
                fontSize: '12px',
                marginTop: '-10px',
                marginLeft: '-10px'
            }
        },
        l_p_6_2: {
            left: '11%',

            '& span[class^="text"]': {
                fontSize: '10px',
            }
        },
        l_p_6_3: {
            left: '14%',
            width: '51%'
        },
        l_p_6_4: {
            top: '83%',
            left: '30%',
            width: '52%',
        },
    },
    '@media (max-width: 280px)' : {
        l_p_3_0: {
            top: '1.5%',

            '& span[class^="text"]': {
                marginTop: '-5px',
                marginLeft: '-5px',
            },
        },
        l_p_3_1: {
            top: '64%',
            left: '12%',
            width: '45%',

            '& span[class^="text"]': {
                fontSize: '9px'
            },
        },
        l_p_6_1: {
            left: '34%',

            '& span[class^="text"]': {
                marginTop: '-20px',
                marginLeft: '-20px',
            }
        },
        l_p_6_2: {
            left: '11%',
            width: '42%',
        },
        l_p_6_3: {
            top: '71%',
            left: '12%',

            '& span[class^="text"]': {
                fontSize: '12px'
            }
        },
        l_p_6_4: {
            left: '23%',
            width: '60%'
        },
    },
    futureText: {
        width: 'auto',
        top: '0%',
        right: '0%',
        left: 'unset',

        '@media (max-width: 280px)' : {
            '& span[class^="text"]': {
                fontSize: '12px'
            }
        }
    }
});
