import { createUseStyles } from 'react-jss'
import { ThemeProps } from 'themes';

export const useStyles = createUseStyles(({ input, typography }: ThemeProps) => ({
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        background: '#FFFFFF',
        padding: '0 16px 14px',
    },
    buttonsWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexBasis: '15%'
    },
    buttonStyles: {
        background: 'transparent',
        border: 'none',
        color: '#010101',
        cursor: 'pointer'
    },
    nextArrowIcon: {
        width: '6.69px',
        height: '11.44px',
    },
    monthDateWrapper: {
        display: 'flex',
        flexDirection: 'row',
    },
    monthDateStyles: {
        margin: 0,
        lineHeight: '24px'

    },
    calendarStyles: {
        border: 'none',
        borderRadius: '13px',
        background: '#FFFFFF',
        fontFamily: 'SF Pro Text',
        fontStyle: 'normal',
        fontSize: '20px',
        lineHeight: '24px',
        fontWeight: 400,
        letterSpacing: '0.38px',
        color: '#010101',

        '& .react-datepicker__month-container': {
            padding: '28px 16px'
        },

        '& .react-datepicker__header': {
            background: '#FFFFFF',
            borderBottom: 'none',
            borderRadius: '13px',
            padding: 0
        },

        '& .react-datepicker__day-names': {
            marginBottom: '16px'
        },

        '& .react-datepicker__day-name': {
            fontFamily: 'SF Pro Text',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '13px',
            lineHeight: '18px',
            color: '#808080',
            textTransform: 'uppercase'
        },

        '& .react-datepicker__month': {
            margin: 0
        },

        '& .react-datepicker__day--disabled': {
            color: '#C5C5D1'
        },

        '& .react-datepicker__day--keyboard-selected, .react-datepicker__day--selected': {
            background: '#010101',
            borderRadius: '50%',
            color: '#FFFFFF'
        }
    },
    inputWrapper: {
        ...input.inputWrapper,
        padding: '16px',
        marginBottom: '12px'
    },
    inputStyles: {
        ...input.input,
        width: '90%',
        caretColor: 'transparent',
        cursor: 'pointer'
    },
    inputLabel: {
        ...typography.inputLabel
    },
    inputLabelActive: {
        ...typography.inputLabelActive
    },
    inputImage: {
        position: 'absolute',
        right: '16px'
    },
    skipNextButton: {
        background: 'none'
    }
}));
