import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import { RoutePath } from 'routes/route-path.constant';

import { BodyPlanCalculator } from 'services/body-plan-calculator.rx.service';

import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys, MultipleLocalStorageKeys } from 'constants/local-storage';

export const useBodyPlanData = () => {
    const unit = 'lbs';
    const finalDate = dayjs().add(6, 'M');

    const [predictedGoalWeight, setPredictedGoalWeight] = useState<number>(0);
    const [monthsPlan, setMonthsPlan] = useState<string[]>([]);
    const [monthsPlanForDisplay, setMonthsPlanForDisplay] = useState<string[]>([]);
    const [weightsPlan, setWeightsPlan] = useState<number[]>([]);
    const [weightsPlanForDisplay, setWeightsPlanForDisplay] = useState<number[]>([]);

    const bodyHeightWeightKeys = MultipleLocalStorageKeys.bodyHeightWeightKeys;

    const { pageValue: weightLbs }: any = useLocalStorage({ key: bodyHeightWeightKeys.weightLbs, defaultValue: '' });
    const { pageValue: goalWeightLbs }: any = useLocalStorage({
        key: LocalStorageKeys[RoutePath.BodyIdealWeight],
        defaultValue: '',
    });

    useEffect(() => {
        const weight = parseInt(weightLbs);
        const goalWeight = parseInt(goalWeightLbs);

        const bodyPlan = new BodyPlanCalculator({ weight, goalWeight });

        const { predictedGoalWeight, weightsPlan, weightsPlanForDisplay, monthsPlan, monthsPlanForDisplay } = bodyPlan.getWeightLossData();

        setMonthsPlan(monthsPlan);
        setMonthsPlanForDisplay(monthsPlanForDisplay);
        setWeightsPlan(weightsPlan);
        setWeightsPlanForDisplay(weightsPlanForDisplay);
        setPredictedGoalWeight(predictedGoalWeight)

    }, [weightLbs, goalWeightLbs]);

    return {
        unit,
        finalDate,
        weightLbs,
        monthsPlan,
        monthsPlanForDisplay,
        weightsPlan,
        weightsPlanForDisplay,
        predictedGoalWeight,
    };
};
