type TitleData = {
    value: string;
    range: [number, number];
    time: number;
}

type PopupAnswer = {
    key: string;
    value: string;
}

type PopupData = {
    title: string;
    event: string;
    storageKey: string;
    answers: PopupAnswer[];
}

type PlanLoaderStep = {
    titles: TitleData[];
    popUpTime?: number;
    popUpData?: PopupData;
}

export type PlanLoaderDataType = PlanLoaderStep[];

export const planLoaderData: PlanLoaderDataType = [
    {
        'titles': [
            {
                'value': 'plan-loader-analyzing-profile-title',
                'range': [0, 55],
                'time': 3000,
            },
            {
                'value': 'plan-loader-comparing-demographic-data-title',
                'range': [56, 100],
                'time': 4000,
            }
        ],
        'popUpTime': 64,
        'popUpData': {
            'title': 'plan-loader-popup-family-disease-title',
            'storageKey': 'heart-disease-diabetes-history',
            'event': 'OnboardingPopupQuestionFamilyDiseaseClicked',
            'answers': [
                {
                    'key': 'yes',
                    'value': 'plan-loader-popup-family-disease-yes-answer',
                },
                {
                    'key': 'no',
                    'value': 'plan-loader-popup-family-disease-no-answer',
                },
            ],
        },
    },
    {
        'titles': [
            {
                'value': 'plan-loader-matching-motivation-trends-title',
                'range': [0, 100],
                'time': 6500,
            }
        ],
        'popUpTime': 55,
        'popUpData': {
            'title': 'plan-loader-popup-work-activity-title',
            'event': 'OnboardingPopupQuestionWorkActivityClicked',
            'storageKey': 'work-activity',
            'answers': [
                {
                    'key': 'active',
                    'value': 'plan-loader-popup-family-disease-active-answer',
                },
                {
                    'key': 'seated',
                    'value': 'plan-loader-popup-family-disease-seated-answer',
                },
            ],
        },
    },
    {
        'titles': [
            {
                'value': 'plan-loader-evaluating-medication-preferences-title',
                'range': [0, 60],
                'time': 5000,
            },
            {
                'value': 'plan-loader-predicting-future-results-title',
                'range': [61, 100],
                'time': 3000,
            },
        ],
        'popUpTime': 70,
        'popUpData': {
            'title': 'plan-loader-popup-meals-time-title',
            'event': 'OnboardingPopupQuestionMealsClicked',
            'storageKey': 'same-time-a-day-meals',
            'answers': [
                {
                    'key': 'yes',
                    'value': 'plan-loader-popup-meals-time-yes-answer',
                },
                {
                    'key': 'no',
                    'value': 'plan-loader-popup-meals-time-no-answer',
                },
            ],
        },
    }
];
