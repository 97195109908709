import React, { FC } from 'react';

import { useStyles } from './styles';

interface PrimerLoaderProps {
    loaderPosition?: 'absolute' | 'relative';
}

export const PrimerLoader:FC<PrimerLoaderProps> = ({ loaderPosition = 'absolute' }): JSX.Element => {
    const { primerLoading, spinner, path } = useStyles();

    return (
        <div style={{ position: loaderPosition }} className={primerLoading}>
            <svg className={spinner} viewBox="0 0 50 50">
                <circle className={path} cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
            </svg>
        </div>
    );
};
