import * as React from 'react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { LastLocationContext, LastLocationType } from '../last-location-provider/last-location.context';
import { LastLocationKey } from 'constants/last-location';

let lastLocation: LastLocationType = null;

type UpdateLastLocation = {
    location: LastLocationType;
    nextLocation: LastLocationType;
};

interface Props {
    children: React.ReactNode;
}

type State = Readonly<{
    currentLocation: LastLocationType;
}>;

export const LastLocationProvider = ({ children }: Props) => {
    const location = useLocation();
    const [state, setState] = useState<State>({
        currentLocation: null
    });

    const updateLastLocation = ({ location, nextLocation }: UpdateLastLocation) => {
        if (location === null) {
            return;
        }

        if (nextLocation === location) {
            return;
        }

        lastLocation = { ...location };
        localStorage.setItem(LastLocationKey, JSON.stringify(lastLocation));
    };

    useEffect(() => {
        const storedLocation = localStorage.getItem(LastLocationKey);
        if (storedLocation) {
            lastLocation = JSON.parse(storedLocation);
        }
    }, []);

    useEffect(() => {
        updateLastLocation({
            location: state.currentLocation,
            nextLocation: location,
        });

        setState({ currentLocation: location });
    }, [location, state.currentLocation]);

    return (
        <LastLocationContext.Provider value={lastLocation}>
            {children}
        </LastLocationContext.Provider>
    );
};
