import i18n from '../../../translations/i18n';

export const ftValidationQuery: any = {
    type: {
        value: 'number',
        moreThen: {
            value: 1,
            errorMessage: i18n.t('height-validation-error-message'),
        },
        lessThen: {
            value: 9,
            errorMessage: i18n.t('height-validation-error-message'),
        },
    },
};

export const inchesValidationQuery: any = {
    type: {
        value: 'number',
        lessThen: {
            value: 12,
            errorMessage: i18n.t('height-validation-error-message'),
        },
    },
};
