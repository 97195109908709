import { useEffect, useState } from 'react';

const loadingRanges = [
    {
        range: [0, 20],
        time: 500
    },
    {
        range: [21, 50],
        time: 5000
    },
    {
        range: [51, 85],
        time: 2000
    },
    {
        range: [86, 100],
        time: 100
    }
];

export const useLoadingProgress = ({ onDone }: { onDone: () => void }) => {
    const [progress, setProgress] = useState({ percents: 0, label: '' });
    const [step, setStep] = useState(0);

    useEffect(() => {
        const stepInterval = loadingRanges[step].time;
        let percents = progress.percents;
        let timeoutId: any;

        let intervalId = setInterval(() => {
            percents = Math.floor(Math.min(percents + 1, 100));

            if (percents === 100) {
                if (onDone) {
                    timeoutId = setTimeout(onDone, 500);
                }
                clearInterval(intervalId);
            }

            if (percents === loadingRanges[step].range[1]) {
                setStep((step + 1) % 4)
            }

            setProgress({
                percents,
                label: `${percents}%`,
            });
        }, stepInterval / (loadingRanges[step].range[1] - loadingRanges[step].range[0]));

        return () => {
            clearTimeout(timeoutId);
            clearInterval(intervalId);
        };
    }, [onDone, progress.percents]);

    return { progress };
};
