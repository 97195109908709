import React from 'react';

import { GoalChartLoseWeight, GoalChartLoseWeightType } from '../lose-weight';

type WeightChartType = { goal?: string } & GoalChartLoseWeightType;

export const WeightChart = ({ weightPlan, monthsPlan, goal, unit, goalWeight }: WeightChartType) => {
    goal = goal ? goal : 'lose-weight';
    return (
        <>
            {goal === 'lose-weight' &&
                <GoalChartLoseWeight
                    unit={unit}
                    weightPlan={weightPlan}
                    monthsPlan={monthsPlan}
                    goalWeight={goalWeight}
                />
            }
        </>
    );
};
