import { APP_PLATFORM_ANDROID, APP_PLATFORM_IOS } from 'constants/app-name';

export const detectDeviceType = () => {
    const userAgent = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent)) {
        return 'tablet';
    } else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(userAgent)) {
        return 'mobile';
    }
    return 'desktop';
};

export const isDesktop = () => {
    return detectDeviceType() !== 'mobile';
};

export const detectIosDevice = () => {
    const iOSDeviceRegex = /iPad|iPhone|iPod/;
    const isIOS = iOSDeviceRegex.test(navigator.userAgent);
    const isTouchDevice = "ontouchend" in document;
    return isIOS && isTouchDevice;
}

export const detectPlatform = () => {
    return detectIosDevice() ? APP_PLATFORM_IOS : APP_PLATFORM_ANDROID;
}

export const detectBrowser = () => {
    const userAgent = window.navigator.userAgent;

    if (userAgent.indexOf("Firefox") !== -1) {
        return "Mozilla Firefox";
    } else if (userAgent.indexOf("Chrome") !== -1) {
        return "Google Chrome";
    } else if (userAgent.indexOf("Safari") !== -1) {
        return "Apple Safari";
    } else if (userAgent.indexOf("Edge") !== -1) {
        return "Microsoft Edge";
    } else if (userAgent.indexOf("Opera") !== -1 || userAgent.indexOf("OPR") !== -1) {
        return "Opera";
    } else if (userAgent.indexOf("Trident") !== -1) {
        return "Internet Explorer";
    } else {
        return "Unknown Browser";
    }
}
