import { Palette } from '../index';

export type TabsType = {
    tabsWrapper: {
        maxWidth: string,
        background: string,
        height: string | number,
        padding: string | number,
        borderRadius: string | number,
        margin: string,
    }
    tabButton: {
        border: string,
        cursor: string,
        height: string,
        padding: string | number,
        minWidth: string | number,
        borderRadius: string | number,
        background?: Palette | string,
        color?: Palette | string,
    },
    tabButtonActive: {
        background?: Palette | string
        color?: Palette | string,
    }
}

export const TabsStyles: TabsType = {
    tabsWrapper: {
        maxWidth: '560px',
        background: '#E0E3EB',
        height: 36,
        padding: 2,
        borderRadius: 8,
        margin: '32px auto',
    },
    tabButton: {
        background: '#E0E3EB',
        color: '#010101',
        border: 'none',
        cursor: 'pointer',
        height: '100%',
        padding: 0,
        minWidth: 'auto',
        borderRadius: 8,
    },
    tabButtonActive: {
        background: '#423BE6',
        color: '#FFFFFF'
    },
};
