import { isString } from 'helpers';

import { IntakeFormKey } from 'constants/intake-form';

type Question = {
    question: string;
    value: string | Array<string> | { unit: any; height: any; weight: any };
};

type Props = {
    key: string;
    itemQuestion: Question;
    userStatus?: Question;
};

export const useSaveAnswers = () => {
    let questions = localStorage.getItem(IntakeFormKey);

    if (!questions) {
        localStorage.setItem(IntakeFormKey, JSON.stringify({}));
        questions = localStorage.getItem(IntakeFormKey);
    }

    return (props: Props) => {
        const questionsObj = JSON.parse(questions as string) as { [key: string]: Question };

        let value = Array.isArray(props.itemQuestion.value) ? props.itemQuestion.value.join(', ') : props.itemQuestion.value;

        if (isString(value)) {
            // @ts-ignore
            value = value.replace('“', '"').replace('”', '"').replace('’', "'");
        }

        const question = props.itemQuestion.question
            .replace(/<[^>]*>?/gm, '')
            .replace('“', '"')
            .replace('”', '"')
            .replace('’', "'");

        questionsObj[props.key] = { question, value };

        let userStatus = props.userStatus;

        if (!props.userStatus) {
            userStatus = JSON.parse(questions as string).userStatus;
        }

        localStorage.setItem(IntakeFormKey, JSON.stringify({ ...questionsObj, userStatus }));
    };
};

export const useGetAnswers = (key: string) => {
    let questions = localStorage.getItem(IntakeFormKey);

    const questionsObj = JSON.parse(questions as string) as { [key: string]: Question };
    const answers = questionsObj[key]?.value;

    // @ts-ignore
    return Array.isArray(answers) ? answers : questionsObj[key]?.value?.split(', ');
};
