import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export const useImportantDateCalculation = (eventDate: string, monthsPlan: string[], weightPlan: number[], monthsPlanForDisplay: string[]) => {

    // @tip: Same format should be at DatePicker - dateFormat='MMM dd, yyyy' (ImportantEventDate component)
    const FORMAT = 'MMM DD, YYYY';

    const [isFutureDate, setIsFutureDate] = useState(false);
    const [isToday, setIsToday] = useState(false);
    const [isWithinWeek, setIsWithinWeek] = useState(false);

    const [eventDateMonthIndex, setEventDateMonthIndex] = useState(0);

    const [weightAtEventDate, setWeightAtEventDate] = useState(0);

    const [position, setPosition] = useState(0);
    const [positionForDisplay, setPositionForDisplay] = useState(0);

    useEffect(() => {
        const eventDateIndex = dayjs(eventDate, FORMAT).month();
        const monthsPlanIndex = monthsPlan.map((month) => dayjs(`${month} 01, 2024`, FORMAT).month());
        const position = monthsPlanIndex.indexOf(eventDateIndex);

        const isNextYear = dayjs(eventDate, FORMAT).isAfter(dayjs(), 'year');
        const isAfter = monthsPlanIndex.every((mIndex) => eventDateIndex > mIndex);
        const isToday = dayjs().format(FORMAT) === eventDate;

        const isFuture = isNextYear || isAfter || (monthsPlanIndex[monthsPlanIndex.length - 1] === eventDateIndex && !isNextYear);

        const today = dayjs().startOf('day');
        const oneWeekFromToday = today.add(1, 'week');
        const isWithinWeek = dayjs(eventDate, FORMAT).isAfter(today) && dayjs(eventDate, FORMAT).isBefore(oneWeekFromToday);

        const monthsPlanForDisplayIndex = monthsPlanForDisplay.map((month) => dayjs(`${month} 01, 2024`, FORMAT).month());

        let positionForDisplay = eventDateIndex - monthsPlanForDisplayIndex[0];

        if (positionForDisplay > 0) {
            positionForDisplay--;
        }

        setPosition(position);
        setPositionForDisplay(positionForDisplay);
        setIsFutureDate(isFuture);
        setIsToday(isToday);
        setIsWithinWeek(isWithinWeek);
        setEventDateMonthIndex(eventDateIndex);
    }, [ eventDate, monthsPlan, monthsPlanForDisplay ]);

    useEffect(() => {
        if (weightPlan.length) {
            let plan = Math.round(weightPlan[position]);

            if (isFutureDate) {
                plan = weightPlan.at(-1) as number;
            }

            setWeightAtEventDate(plan);
        }
    }, [ weightPlan, eventDateMonthIndex, position, isFutureDate, positionForDisplay ]);

    return {
        position,
        isFutureDate,
        isToday,
        isWithinWeek,
        weightAtEventDate,
        positionForDisplay
    };
};
