class UserGeolocation {

    getUserData() {
        return fetch(`${process.env.REACT_APP_GEOCODING_API_URL}`, { method: 'GET', mode: 'cors' })
            .then(response => response.json())
            .catch((e) => console.log(e));
    }
}

export const UserGeolocationProvider = new UserGeolocation();
