import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(localeData);

dayjs().localeData();

type PropsType = {
    weight: number;
    goalWeight: number;
};

export class BodyPlanCalculator {
    weight: number;
    goalWeight: number;

    constructor({ weight, goalWeight }: PropsType) {
        this.weight = weight;
        this.goalWeight = goalWeight;
    }

    getWeightLossData() {
        const totalLoseWeight = (15 / 100) * this.weight;
        const lossPerMonth = totalLoseWeight / 6;
        const predictedFinalWeight = this.weight - totalLoseWeight;

        return {
            predictedGoalWeight: Math.floor(predictedFinalWeight),
            weightsPlan: this.getWeightsPlan(lossPerMonth),
            weightsPlanForDisplay: this.getShowedWeights(lossPerMonth),
            monthsPlan: this.getMonthsPlan(),
            monthsPlanForDisplay: this.getShowedMonths()
        }
    }

    private getWeightsPlan = (lossPerMonth: number): number[] => {
        const weightsPerMonth: number[] = [];

        for (let month = 1; month <= 6; month++) {
            const weightForMonth = this.weight - (month * lossPerMonth);
            weightsPerMonth.push(Math.floor(weightForMonth));
        }

        return weightsPerMonth;
    };

    private getShowedWeights = (lossPerMonth: number) => {

        return new Array(7)
            .fill(this.weight)
            .map((v, i) => Math.round(v - i * lossPerMonth))
            .filter((_, i) => !(i % 3));
    };

    private getMonthsPlan = () => {
        const current = dayjs().format('MMM');
        const last = dayjs().add(6, 'M').format('MMM');

        const all = dayjs.monthsShort();

        const startIndex = all.indexOf(current);
        const endIndex = all.indexOf(last);

        let monthPLan;
        if (startIndex <= endIndex) {
            monthPLan = all.slice(startIndex, endIndex + 1);
        } else {
            monthPLan = all.slice(startIndex).concat(all.slice(0, endIndex + 1));
        }

        return monthPLan;
    }

    private getShowedMonths = () => {
        const monthPLan = this.getMonthsPlan();

        const filteredMonths = monthPLan.filter((_, i) => !(i % 3));

        if (filteredMonths.length === 2) {
            return [filteredMonths[0], monthPLan[monthPLan.length - 1]];
        } else {
            return filteredMonths;
        }
    }
}

export const getBmi = (weight: number, height: number): number => {
    return weight / (height * height) * 703;
};
