import { useEffect, useState } from 'react';

export const useDesktopMediaWatcher = () => {
    const [isDesktop, setIsDesktop] = useState(false);

    useEffect(() => {
        const mediaWatcher = window.matchMedia('(min-width: 768px)');
        setIsDesktop(mediaWatcher.matches);

        const updateIsNarrowScreen = (e: MediaQueryListEvent) => {
            setIsDesktop(e.matches);
        };

        if (mediaWatcher.addEventListener) {
            mediaWatcher.addEventListener('change', updateIsNarrowScreen);
            return function cleanup() {
                mediaWatcher.removeEventListener('change', updateIsNarrowScreen);
            };
        }
    });

    return isDesktop;
}
