import { ABLE_PLAN } from 'constants/able-plan';
import { LocalStorageKeys } from 'constants/local-storage';
import { RoutePath } from 'routes/route-path.constant';

export const handleRedirectToPwa = () => {
    const email = localStorage.getItem(LocalStorageKeys[RoutePath.Email]) as string;
    const encodedEmail = encodeURIComponent(email);

    const queryParams = `plan=${ABLE_PLAN}&email=${encodedEmail}`;
    window.location.href = `${process.env.REACT_APP_PWA_URL}?${queryParams}`;
};
