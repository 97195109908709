import React from 'react';
import { useTranslation } from 'react-i18next';

import { MedicationAccordion } from '../medication-accordion';

export const FAQ = () => {

    const { t } = useTranslation();

    const items = [
        {
            id: 1,
            title: t('medication-faq-item-1-title'),
            description: t('medication-faq-item-1-description')
        },
        {
            id: 2,
            title: t('medication-faq-item-2-title'),
            description: t('medication-faq-item-2-description')
        },
        {
            id: 3,
            title: t('medication-faq-item-3-title'),
            description: t('medication-faq-item-3-description')
        },
        {
            id: 4,
            title: t('medication-faq-item-4-title'),
            description: t('medication-faq-item-4-description')
        }
    ];

    return (
        <MedicationAccordion title={t('medication-faq-title')} listedItems={items} />
    );
};
