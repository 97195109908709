import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    shippingAddressFormWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px'
    },
    multipleFieldsWrapper: {
        display: 'flex',
        width: '100%',
        gap: '16px',
        maxWidth: '560px',
        margin: '0 auto',
        '& div': {
            width: '100%',
        }
    },
    googleAutocompleteBlock: {
        width: '90%',
        position: 'absolute',
        top: '55px',
        background: '#FFFFFF',
        boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.2)',
        zIndex: 1,
        borderRadius: '0 0 8px 8px',

    },
    block: {
        display: 'block',
    },
    suggestionItem: {
        padding: '8px',
        borderBottom: '1px solid #DDDDDD',

        '&:last-child': {
            borderBottom: 'none',
        }
    },
    suggestionItemActive: {
        padding: '8px',
        background: '#DDDDDD',
        cursor: 'pointer'
    },
    description: {
        fontSize: '14px',
        lineHeight: '20px'
    },
    error: {
        color: '#FF4D2B',
        lineHeight: '20px',
        textAlign: 'initial',
        margin: '0 0 16px',
    }
});
