export type InputType = {
    inputWrapper: any,
    input: any,
    inputActiveClass: any,
    inputActiveClassDark: any,
    inputErrorClass: any,
    inputWrapperDark: any,
    inputDark: any,
};

export const InputStyles: InputType = {
    inputWrapper: {
        position: 'relative',
        background: '#FAFAFA',
        borderRadius: '.5rem',
        border: '1px solid #C5C5D1',
        boxSizing: 'border-box',
        display: 'flex',
        fontFamily: 'SF Pro Text',
        alignItems: 'center',
        height: '56px',
        padding: '8px 16px',
    },
    input: {
        border: 'none',
        outline: 'none',
        background: 'none',
        all: 'unset',
        display: 'block',
        width: '100%',
        boxSizing: 'border-box',
        fontSize: '1rem',
        lineHeight: '1.5rem',
        borderRadius: '.5rem',
        color: '#010101',
        marginTop: '20px',
        caretColor: '#1375D6',

        '&::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
        }
    },
    inputActiveClass: {
        border: '1px solid #1375D6'
    },
    inputErrorClass: {
        border: '1px solid #DD3B3B'
    },

    // Dark Theme required for /shipping-address-embedded page embedded to the WebView
    inputWrapperDark: {
        background: '#010101',
        border: '1px solid #373739'
    },
    inputDark: {
        color: '#FFFFFF',
        caretColor: '#8E8BF3'
    },
    inputActiveClassDark: {
        border: '1px solid #8E8BF3'
    }
};
