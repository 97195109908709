export const reviewsConfig = [
    {
        name: 'medication-video-customer-review-name-geraldine',
        videoId: 'zbxNmHfObss',
        backgroundPhoto: 'linear-gradient(180deg, rgba(32, 43, 71, 0) 0%, rgba(32, 43, 71, 0) 46.48%, rgba(32, 43, 71, 0.8) 73.87%, #202B47 100%), url(https://uploads-ssl.webflow.com/61fab9ed2443e9401829f989/63336cc1195ea5cf84bc519d_Geraldine.webp)',
        review: 'medication-video-customer-review-weight-loss-geraldine'
    },
    {
        name: 'medication-video-customer-review-name-audriana',
        videoId: 'hIEaEiVg4pg',
        backgroundPhoto: 'linear-gradient(180deg, rgba(32, 43, 71, 0) 0%, rgba(32, 43, 71, 0) 46.48%, rgba(32, 43, 71, 0.8) 73.87%, #202B47 100%), url(https://uploads-ssl.webflow.com/61fab9ed2443e9401829f989/63336ca773ead5fd7dab7188_Captura%20de%20pantalla.webp)',
        review: 'medication-video-customer-review-weight-loss-audriana'
    },
    {
        name: 'medication-video-customer-review-name-barbora',
        videoId: 'n3M4Pxa6zQg',
        backgroundPhoto: 'linear-gradient(180deg, rgba(32, 43, 71, 0) 0%, rgba(32, 43, 71, 0) 46.48%, rgba(32, 43, 71, 0.8) 73.87%, #202B47 100%), url(https://uploads-ssl.webflow.com/61fab9ed2443e9401829f989/63336c99844eea18ca7a0df2_Captura%20de%20pantalla%202022-08-16%20a%20las%2015.40%202.webp)',
        review: 'medication-video-customer-review-weight-loss-barbora'
    },
    {
        name: 'medication-video-customer-review-name-sam',
        videoId: 'ZTwGUI2AsE8',
        backgroundPhoto: 'linear-gradient(180deg, rgba(32, 43, 71, 0) 0%, rgba(32, 43, 71, 0) 46.48%, rgba(32, 43, 71, 0.8) 73.87%, #202B47 100%), url(https://uploads-ssl.webflow.com/61fab9ed2443e9401829f989/63a32c890290cf184e37e7ef_sam.png)',
        review: 'medication-video-customer-review-weight-loss-sam'
    },
    {
        name: 'medication-video-customer-review-name-andy',
        videoId: 'JmR4lKx8t5M',
        backgroundPhoto: 'linear-gradient(180deg, rgba(32, 43, 71, 0) 0%, rgba(32, 43, 71, 0) 46.48%, rgba(32, 43, 71, 0.8) 73.87%, #202B47 100%), url(https://uploads-ssl.webflow.com/61fab9ed2443e9401829f989/63a32cbcddd3196660323ebc_andy.png)',
        review: 'medication-video-customer-review-weight-loss-andy'
    }
];
