import React from "react";

import { ThemeProvider } from 'react-jss';

import { defaultTheme, ThemeProps } from 'themes';

import { customTheme } from './custom-theme';

export const AppThemeProvider = ({ children }: any) => {
    let theme: Partial<ThemeProps> = defaultTheme;

    const localTheme = localStorage.getItem('theme');

    if (localTheme === 'custom') {
        theme = customTheme;
    }

    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    )
};
