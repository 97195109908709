import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    primerLoading: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 100,
        pointerEvents: 'none'
    },
    spinner: {
        animation: '1.2s linear 0s infinite normal none running $rotate',
        height: '32px',
        width: '32px',
        position: 'absolute',
        top: '40%',
    },
    path: {
        stroke: 'rgb(170, 170, 170)',
        strokeLinecap: 'round',
        animation: '1.5s ease-in-out 0s infinite normal none running $pathAnimation'
    },
    '@keyframes rotate': {
        '100%': {
            '-webkit-transform': 'rotate(360deg)',
            transform: 'rotate(360deg)'
        }
    },
    '@keyframes pathAnimation': {
        '0%': {
            strokeDasharray: '1, 150',
            strokeDashoffset: '0'
        },
        '100%': {
            strokeDasharray: '90, 150',
            strokeDashoffset: '-124'
        }
    }
});
