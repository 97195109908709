import i18n from '../../../translations/i18n';

export const lbsValidationQuery: any = {
    type: {
        value: 'number',
        moreThen: {
            value: 59,
            errorMessage: i18n.t('weight-validation-error-message'),
        },
        lessThen: {
            value: 1002,
            errorMessage: i18n.t('weight-validation-error-message'),
        },
    },
};
